import { useEffect, useMemo, useState } from 'react';
import {
  compareDesc,
  formatISO,
  getUnixTime,
} from 'date-fns';
import { useDispatch } from 'react-redux';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Event } from '../../core/types/util/event';
import { Header } from '../../components/header';
import { Modal } from '../../shared/components/modal';
import { MultiSearchSelect } from '../../shared/components/multiSearchSelect';
import { Page } from '../../shared/components/page';
import { Planner } from '../../components/planner';
import { ProjectDetails } from '../../components/projectDetails';
import { ProjectsPicker } from '../../components/projectsPicker';
import { WorkerDetails } from '../../components/workerDetails';
import { NewTask } from '../../core/api/newTask';
import { task2MoveTask } from '../../core/helpers/converters';
import { useSelector } from '../../core/hooks/redux';
import {
  SortOptions,
  setSortBy,
  setWorkerEmploymentTypes,
  setWorkerJobRoles,
  setWorkerLeaders,
  sortOptions,
  useOrganizations,
} from '../../core/redux/leftFilterState';
import { setWorkerProjects } from '../../core/redux/taskFilterState';
import {
  setProjectOrg,
  setProjectSort,
  setWorkerProjects as setRightProjects,
} from '../../core/redux/rightFilterState';
import {
  useGetAllWorkersQuery,
  useGetAllProjectsQuery,
  useGetOrganizationsQuery,
} from '../../core/redux/worker';
import { TaskType } from '../../core/types/enums/taskType';
import { labelSort } from '../../core/helpers/functions';
import { TaskStatus } from '../../core/types/enums/taskStatus';
import { CalendarView } from '../../core/types/util/calendarView';
import {
  eventBackgroundColor,
  eventDefaultEnd,
  eventDefaultStart,
  useIsLoaned,
} from '../../core/helpers/taskHelpers';
import { useTimeFrameStrings } from '../../core/hooks/useTimeframe';
import { ProjectFavourite } from '../../components/projectFavourite';
import { Project } from '../../core/types/project';
import { useCombinedLoading } from '../../core/hooks/useCombinedLoading';
import { PopMenuHeader } from '../../components/popMenuHeader';
import { ProjectSortTypes } from '../../core/types/enums/projectSortTypes';
import { useDisplayableDummies } from '../../core/hooks/useDummies';
import { useTasks } from '../../core/hooks/useTasks';
import { TaskEvent } from '../../core/types/taskEvent';
import './style.scss';
import { SearchSelectDirect } from '../../shared/components/searchSelect';
import { SelectItem } from '../../shared/types/util/selectItem';
import { translateSortOption } from '../../core/helpers/translate';
import { RootState } from '../../core/redux';

export const WorkerPlanner = () => {
  const [selectedProject, setSelectedProject] = useState<{id: number|string, title: string}|null>(null);
  const [rawSelectedWorker, setSelectedWorker] = useState<{id: number, title: string}|null>(null);
  const organizations = useOrganizations();
  const timeframe = useTimeFrameStrings();
  const isLoaned = useIsLoaned();
  const dummies = useDisplayableDummies();

  const leftLeaderFilter = useSelector((l) => l.leftFilterState.worker.leaders) || [];
  const leftWorkerSearch = useSelector((s) => s.leftFilterState.worker.search) || '';
  const leftEmploymentTypeFilter = useSelector((l) => l.leftFilterState.worker.employmentTypes) || [];
  const leftJobRoleFilter = useSelector((l) => l.leftFilterState.worker.jobRoles) || [];
  const leftSortBy = useSelector((l) => l.leftFilterState.worker.sortBy ?? 'stucture');
  const taskProjectFilter = useSelector((s) => s.taskFilterState.worker.projects) || [];
  const rightProjectFilter = useSelector((s) => s.rightFilterState.worker.projects) || [];
  const rightProjectSearch = useSelector((s) => s.rightFilterState.worker.search) || '';
  const rightProjectSort = useSelector((s) => s.rightFilterState.worker.sort) || 'id';
  const rightProjectOrg = useSelector((s) => s.rightFilterState.worker.org) || [];
  const rightProjectIncludeInternalProduction = useSelector((s) => s.rightFilterState.worker.includeInternalProduction) || false;
  const rightProjectIncludeOwnProjects = useSelector((s) => s.rightFilterState.worker.includeOwnProjects) || false;
  const showStangelandProsjektWorkers = useSelector(
    (state: RootState) => state.viewSetting.showStangelandProsjektWorkers,
  );
  const dispatch = useDispatch();

  const { data: workers, isLoading: leftLoading, isFetching: workersFetching } = useGetAllWorkersQuery({ organizations, ...timeframe });
  const { data: projects, isLoading: rightLoading, isFetching: projectsFetching } = useGetAllProjectsQuery({
    organizations: rightProjectOrg.length > 0 ? rightProjectOrg.map((o) => o.id as number) : organizations,
    includeInternalProduction: rightProjectIncludeInternalProduction,
    includeOwnProjects: rightProjectOrg.length === 0 && organizations.length === 0 ? true : rightProjectIncludeOwnProjects,
  }, { pollingInterval: 60000 });

  const { data: orgProjects } = useGetAllProjectsQuery({ organizations, includeInternalProduction: true });

  const {
    tasks,
    isLoading: taskLoading,
    move,
    add,
    remove,
  } = useTasks();
  const { data: projectOrgs } = useGetOrganizationsQuery();

  const anyFetching = useCombinedLoading(workersFetching, projectsFetching);

  const anyLoading = useCombinedLoading(leftLoading, rightLoading, taskLoading);

  const selectedWorker = useMemo(() => {
    if (rawSelectedWorker && workers?.some((w) => w.employeeNumber === rawSelectedWorker.id)) {
      return rawSelectedWorker;
    }
    return null;
  }, [workers, rawSelectedWorker]);

  const onEventAdded = (e: TaskEvent, v: CalendarView, _: any, copied?: boolean, split?: boolean) => {
    const body: NewTask = {
      projectId: parseInt(e.id, 10),
      workerId: e.workerType ? undefined : e.parent as number,
      startTime: copied ? formatISO(e.startTime) : eventDefaultStart(e.startTime, v),
      endTime: copied ? formatISO(e.endTime) : eventDefaultEnd(e.endTime, v),
      workerType: e.workerType,
      title: 'test',
      comment: e.comment,
      split,
      type: e.type ?? 'Normal',
    };
    if (workers?.some((w) => w.employeeNumber === e.parent)) {
      body.workerType = undefined;
      body.workerId = e.parent as number;
    } else {
      body.workerType = `${e.parent}`;
      body.workerId = undefined;
    }
    add(body, `${e.title}`);
  };

  const moveTask = (e: TaskEvent, _: any, split?: boolean) => {
    const task = tasks?.find((t) => `${t.id}` === `${e.id}`);
    if (!task) return;
    const updateTaskData = task2MoveTask(task);
    updateTaskData.startTime = formatISO(e.startTime);
    updateTaskData.endTime = formatISO(e.endTime);
    updateTaskData.split = split;

    if (workers?.some((w) => w.employeeNumber === e.parent)) {
      updateTaskData.workerType = undefined;
      updateTaskData.workerId = e.parent as number;
    } else {
      updateTaskData.workerType = `${e.parent}`;
      updateTaskData.workerId = undefined;
    }
    if (task.type === 'Unavailability' || task.type === 'Loan') {
      updateTaskData.workerId = task.worker?.employeeNumber;
      updateTaskData.workerType = task.workerType;
    }
    move(`${e.id}`, updateTaskData, `${e.title}`);
  };

  const plannerWorkers = useMemo(() => {
    const work = workers
      ?.filter((w) => leftLeaderFilter.length === 0 || leftLeaderFilter.some((l) => l.id === w.leaderId || l.id === w.employeeNumber))
      .filter((w) => leftEmploymentTypeFilter.length === 0 || leftEmploymentTypeFilter.some((t) => t.label === w.employmentType))
      .filter((w) => leftJobRoleFilter.length === 0 || leftJobRoleFilter.some((j) => j.id === w.jobRole))
      .filter((w) => (!showStangelandProsjektWorkers ? w.company === 'Stangeland Maskin AS' : true))
      .filter(
        (w) => leftWorkerSearch === ''
          || w.fullName
            .toLocaleLowerCase()
            .includes(leftWorkerSearch.toLocaleLowerCase())
          || `${w.employeeNumber}`.includes(leftWorkerSearch),
      )
      ?.map((e) => ({
        ...e,
        id: e.employeeNumber,
        label: e.fullName,
        expiredCertifications: e.expiredCertifications,
        missingSecurityTraining: e.missingSecurityTraining,
        tag: e.tag,
        startDate: e.dateOfEmployment,
        endDate: e.lastEmploymentDay,
      })) || [];

    return [...work, ...dummies.map((d) => ({ ...d, id: d.label }))];
  }, [
    workers,
    leftLeaderFilter,
    leftEmploymentTypeFilter,
    leftJobRoleFilter,
    leftWorkerSearch,
    showStangelandProsjektWorkers,
  ]);

  const checkIfEditable = (type: TaskType) => (
    type !== 'ExternalUnavailability'
    && type !== 'Unavailability'
    && type !== 'Loan'
  );

  const checkCanChangeTime = (type: TaskType, status: TaskStatus) => {
    if (type === 'ExternalUnavailability') return false;
    if (type === 'Loan' && status === 'Approved') return false;
    return true;
  };

  const plannerTasks: Event[] = useMemo(() => (
    tasks?.map((t) => {
      let label = `${t.commitPercentage < 100 ? `${t.commitPercentage}% - ` : ''}${t.project?.id} - ${t.project?.projectName}`;
      if (isLoaned(t)) {
        label = '';
      } else if (t.type === 'Loan') {
        label = (`${t.status === 'Approved' ? 'Utlån' : 'Utlånsforespørsel'} til ${t.loanerOrganization?.name}`);
      }
      return {
        id: t.id,
        label,
        start: new Date(t.startTime),
        end: new Date(t.endTime),
        parent: t.worker?.employeeNumber.toString() || t.workerType || '0',
        origin: t.project?.id?.toString() ?? '0',
        color: eventBackgroundColor(t.worker?.employeeNumber, t.type, t.status, t.sickPercentage),
        durationEditable: checkCanChangeTime(t.type, t.status),
        editable: checkCanChangeTime(t.type, t.status),
        resourceEditable: checkIfEditable(t.type),
        ghost: taskProjectFilter.length > 0 && !taskProjectFilter.some((p) => p.id === t.project?.id),
        reverseBackground: isLoaned(t),
        type: t.type,
        title: t.title,
        comment: t.comment,
        sickPercentage: t.sickPercentage,
        workerProjectStart: t.workerProjectStart ? new Date(t.workerProjectStart) : undefined,
        workerProjectEnd: t.workerProjectEnd ? new Date(t.workerProjectEnd) : undefined,
      };
    }) || []
  ), [tasks, taskProjectFilter]);

  const sortProjects = (a: Project, b: Project) => {
    if (rightProjectSort.value === 'name') return b.projectName.localeCompare(a.projectName, 'nb');
    if (rightProjectSort.value === 'startDate') return compareDesc(new Date(a.startDate), new Date(b.startDate));
    if (rightProjectSort.value === 'expectedEndDate') {
      return (b.expectedEndDate ? getUnixTime(new Date(b.expectedEndDate)) : Infinity)
      - (a.expectedEndDate ? getUnixTime(new Date(a.expectedEndDate)) : Infinity);
    }
    return b.id - a.id;
  };

  const projectSort = useMemo(
    () => (a: Project, b: Project) => (rightProjectSort.desc ? sortProjects(a, b) : sortProjects(b, a)),
    [rightProjectSort],
  );

  const plannerProjects = useMemo(() => {
    if (!projects) return [];
    return projects
      .filter((p) => rightProjectFilter.length <= 0 || rightProjectFilter.some((fp) => `${fp.id}` === `${p.operationsManager?.employeeNumber}`))
      .filter((p) => rightProjectSearch === ''
      || p.projectName.toLocaleLowerCase().includes(rightProjectSearch.toLocaleLowerCase())
      || `${p.id}`.includes(rightProjectSearch))
      .sort(projectSort)
      .map((p) => ({
        id: p.id,
        label: `${p.id} - ${p.projectName}`,
        starred: p.favourite,
      }));
  }, [projects, rightProjectFilter, rightProjectSearch, rightProjectSort]);

  const operationsManagers = useMemo(() => {
    const names = new Map<number, string>();
    projects?.forEach((p) => {
      if (p.operationsManager) {
        names.set(p.operationsManager.employeeNumber, p.operationsManager.fullName);
      }
    });
    return Array.from(names, ([id, label]) => ({ id, label })).sort(labelSort);
  }, [projects]);

  const activeLeaders = useMemo(() => {
    const items = new Map<number, string>();
    workers?.forEach((w) => {
      if (w.leaderId && w.leader && workers.some((p) => p.employeeNumber === w.leaderId)) {
        items.set(w.leaderId, w.leader);
      }
    });
    return Array.from(items, ([id, label]) => ({ id, label })).sort(labelSort);
  }, [workers]);

  const employmentTypes = useMemo(() => {
    const items = new Set<string>();
    workers?.forEach((w) => {
      if (w.employmentType) {
        items.add(w.employmentType);
      }
    });
    return Array.from(items, (i) => ({ id: i, label: i })).sort(labelSort);
  }, [workers]);

  const jobRoles = useMemo(() => {
    const items = new Set<string>();
    workers?.forEach((w) => {
      if (w.jobRole) {
        items.add(w.jobRole);
      }
    });
    return Array.from(items, (i) => ({ id: i, label: i })).sort(labelSort);
  }, [workers]);

  /**
   * Clear filters that don't apply when organization changes
   */
  useEffect(() => {
    if (anyLoading || !projects) return;
    dispatch(setWorkerLeaders(
      leftLeaderFilter.filter((l) => activeLeaders.some((a) => a.id === l.id)),
    ));
    dispatch(setWorkerEmploymentTypes(
      leftEmploymentTypeFilter.filter((l) => employmentTypes.some((t) => t.id === l.id)),
    ));
    dispatch(setWorkerJobRoles(
      leftJobRoleFilter.filter((l) => jobRoles.some((t) => t.id === l.id)),
    ));
    dispatch(setWorkerProjects(
      taskProjectFilter.filter((p) => projects.some((f) => f.id === p.id)),
    ));
    dispatch(setRightProjects(
      rightProjectFilter.filter((p) => operationsManagers.some((f) => f.id === p.id)),
    ));
  }, [organizations, projects, operationsManagers, activeLeaders, anyLoading]);

  const taskFilter = (t: Event) => {
    if (t.type !== 'Normal') return true;
    const findOrg = orgProjects?.find((p) => `${p.id}` === t.origin);
    const internalOrgs = findOrg?.internalProduction.map((i) => i.id) || [];
    return organizations.length === 0
      || (organizations.includes(findOrg?.responsible?.parentOrganizationId || 1)
      || organizations.includes(findOrg?.coResponsible?.parentOrganizationId || 1)
      || organizations.some((o) => internalOrgs.includes(o)));
  };

  return (
    <Page className="transportplanlegger-page">
      <Header page="worker" />
      <Planner
        page="worker"
        fetching={anyFetching}
        taskLoading={taskLoading}
        left={plannerWorkers}
        tasks={plannerTasks.filter(taskFilter)}
        right={plannerProjects}
        leftLoading={leftLoading}
        rightLoading={rightLoading}
        onEventChanged={moveTask}
        onEventAdded={onEventAdded}
        onEventRemoved={remove}
        onLeftSelect={(id, title) => setSelectedWorker({ id, title })}
        onRightSelect={(id, title) => setSelectedProject({ id, title })}
        taskFilters={{
          value: (
            <span>
              <ProjectsPicker
                size="small"
                label="Prosjekt"
                value={taskProjectFilter}
                onChange={(ps) => dispatch(setWorkerProjects(ps))}
                projects={projects}
              />
            </span>
          ),
          active: taskProjectFilter.length > 0,
        }}
        rightFilters={(
          <Box display="flex" flexDirection="column" gap={0.5}>
            <PopMenuHeader>Sortering</PopMenuHeader>
            <Box>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={rightProjectSort.value}
                  onChange={(e) => dispatch(setProjectSort({ ...rightProjectSort, value: e.target.value as ProjectSortTypes }))}
                >
                  <MenuItem value="id">Prosjektnummer</MenuItem>
                  <MenuItem value="name">Prosjektnavn</MenuItem>
                  <MenuItem value="startDate">Start dato</MenuItem>
                  <MenuItem value="expectedEndDate">Forventet slutt dato</MenuItem>
                </Select>
                <FormControlLabel
                  label="Synkende rekkefølge"
                  control={(
                    <Checkbox
                      checked={rightProjectSort.desc}
                      onChange={() => dispatch(setProjectSort({ ...rightProjectSort, desc: !rightProjectSort.desc }))}
                    />
                          )}
                />
              </FormControl>
            </Box>
            <PopMenuHeader>Filter</PopMenuHeader>
            <Box display="flex" flexDirection="column" gap={1.5}>
              <MultiSearchSelect
                label="Organisasjon"
                size="small"
                value={rightProjectOrg}
                onChange={(p) => dispatch(setProjectOrg(p))}
              >
                {projectOrgs?.map((o) => ({ id: o.id, label: o.name })).sort((a, b) => a.label.localeCompare(b.label, 'nb'))}
              </MultiSearchSelect>
              <MultiSearchSelect
                label="Driftsleder"
                size="small"
                value={rightProjectFilter}
                onChange={(p) => dispatch(setRightProjects(p))}
              >
                {operationsManagers}
              </MultiSearchSelect>
            </Box>
          </Box>
        )}
        leftFilters={(
          <>
            <Box display="flex" flexDirection="column" gap={2}>
              <span>
                <MultiSearchSelect
                  label="Leder"
                  size="small"
                  value={leftLeaderFilter}
                  onChange={(l) => dispatch(setWorkerLeaders(l))}
                >
                  {activeLeaders}
                </MultiSearchSelect>
              </span>
              <span>
                <MultiSearchSelect
                  label="Ansettelsestype"
                  size="small"
                  value={leftEmploymentTypeFilter}
                  onChange={(l) => dispatch(setWorkerEmploymentTypes(l))}
                >
                  {employmentTypes}
                </MultiSearchSelect>
              </span>
              <span>
                <MultiSearchSelect
                  label="Rolle"
                  size="small"
                  value={leftJobRoleFilter}
                  onChange={(l) => dispatch(setWorkerJobRoles(l))}
                >
                  {jobRoles}
                </MultiSearchSelect>
              </span>
            </Box>
            <Box display="flex" flexDirection="column">
              <PopMenuHeader>Sortering</PopMenuHeader>
              <SearchSelectDirect
                required
                disableClearable
                label=""
                size="small"
                value={leftSortBy}
                onChange={(v) => v && dispatch(setSortBy(v))}
              >
                {sortOptions.map((o): SelectItem<SortOptions> => ({
                  id: o,
                  label: translateSortOption(o),
                }))}
              </SearchSelectDirect>
            </Box>
          </>
        )}
      />

      {selectedProject !== null && typeof selectedProject.id === 'number' && (
        <Modal
          title={(
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {selectedProject.title}
              <ProjectFavourite projectId={selectedProject.id} />
            </Box>
        )}
          onClose={() => setSelectedProject(null)}
        >
          <ProjectDetails id={selectedProject.id} />
        </Modal>
      )}
      {selectedWorker !== null && (
        <Modal title={selectedWorker.title} compactHeader onClose={() => setSelectedWorker(null)}><WorkerDetails id={selectedWorker.id} /></Modal>
      )}

    </Page>
  );
};
